import React from 'react';
import styled, { css } from 'styled-components';

import PageStatic from '../components/page-static';
import Title from '../components/title';
import { Box, Flex, Paragraph, Text } from '../components/UIKit/system';
import Button from '../components/button';
import Container from '../components/container';
import { getPath } from '../utils/paths';
import Link from '../components/link';
import IconHexagon from '../images/svg/icon-hexagon.svg';
import mq from '../utils/mq';
import { OFFSET_SIDE_SM } from '../constants';

function CustomTitle(props) {
  return <Title fontSize={24} letterSpacing={0} {...props} />;
}

const Intro = styled(Box)``;

const StyledContainer = styled(Container)`
  ${mq.mobile(css`
    border: none;
  `)};
`;

const ContentBox = styled(Box)`
  max-width: 974px;
  margin: 0 auto;
  padding: 40px 74px;
  background-color: #fff;

  ${mq.mobile(css`
    padding: 38px ${OFFSET_SIDE_SM}px;
  `)};
`;

const SpecificsSection = styled.div`
  margin-bottom: 44px;
  padding-bottom: 44px;
  border-bottom: 1px solid #ddd;

  &:last-of-type {
    margin-bottom: 0;
    border-bottom: none;
  }
`;

const StyledIconHexagon = styled(IconHexagon)`
  margin-bottom: 15px;
`;

function SpecificsSectionTitle(props) {
  return <Text mb={15} fontSize={18} bold {...props} />;
}

function SectionParagraph(props) {
  return <Paragraph fontSize={15} grey lineHeight={2.13} {...props} />;
}

function ProjectSpecificationCalculation() {
  return (
    <PageStatic seoTitle={'Your specification is ready!'}>
      <Intro pt={[60, 78]} pb={[80, 120]} bg={'primary'}>
        <Container mb={83}>
          <Flex
            textAlign={'center'}
            color={'#fff'}
            flexDirection={'column'}
            alignItems={'center'}
          >
            <Paragraph mb={10} fontSizeExtraSmall bold>
              Completed!
            </Paragraph>
            <Title as={'h1'} mb={32} letterSpacing={0}>
              Your specification is ready!
            </Title>
            <Link to={getPath.specTool()} hover>
              <Paragraph fontSizeExtraSmall bold>
                Restart/New Specification
              </Paragraph>
            </Link>
          </Flex>
        </Container>
        <StyledContainer>
          <ContentBox>
            <CustomTitle mb={12}>Summary</CustomTitle>
            <Flex
              flexDirection={['column', 'row']}
              justifyContent={'space-between'}
            >
              <Paragraph maxWidth={495} mr={[0, 30]} letterSpacing={0} grey>
                Project name will require a mechanical ventilation system
                incorporating a multi-zone controls system and air differential
                pressure sensors.
              </Paragraph>
              <Button mt={20}>Datasheet spec PDF</Button>
            </Flex>
          </ContentBox>
        </StyledContainer>
      </Intro>
      <StyledContainer mt={-100}>
        <ContentBox>
          <CustomTitle mb={35}>Specifics</CustomTitle>
          <Box>
            <SpecificsSection>
              <StyledIconHexagon />
              <SpecificsSectionTitle>Building Height</SpecificsSectionTitle>
              <SectionParagraph>
                As your building is over 30 meters, you will likely require a
                mechanical ventilation system. This is due to recommendations in
                BS9991 which state mechanical ventilation must be used in
                firefighting shafts for buildings taller than 11 meters. This
                guidance is typically construed as being applicable even when
                there is no requirement for a firefighter's shaft. You will also
                be unable to use facade ventilation in areas of your building
                which are above 30 meters. This is outlined in Approved Document
                B Section 3 Subsection 2a where it states that "facade
                ventilation must not be used above 30 meters due to the
                influence of prevailing winds". Caution must also be taken when
                planning etc etc
              </SectionParagraph>
            </SpecificsSection>
            <SpecificsSection>
              <StyledIconHexagon />
              <SpecificsSectionTitle>Number of Stairs</SpecificsSectionTitle>
              <SectionParagraph>
                As your building is over 30 meters, you will likely require a
                mechanical ventilation system. This is due to recommendations in
                BS9991 which state mechanical ventilation must be used in
                firefighting shafts for buildings taller than 11 meters.{' '}
              </SectionParagraph>
            </SpecificsSection>
            <SpecificsSection>
              <StyledIconHexagon />
              <SpecificsSectionTitle>Number of Stairs</SpecificsSectionTitle>
              <SectionParagraph>
                As your building is over 30 meters, you will likely require a
                mechanical ventilation system. This is due to recommendations in
                BS9991 which state mechanical ventilation must be used in
                firefighting shafts for buildings taller than 11 meters.{' '}
              </SectionParagraph>
            </SpecificsSection>
          </Box>
        </ContentBox>
      </StyledContainer>
    </PageStatic>
  );
}

export default ProjectSpecificationCalculation;
